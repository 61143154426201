import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "../../components/Navbar.jsx";
import NavbarMobile from "../../components/NavbarMobile.jsx";
import Footer from "../../components/Footer.jsx";
import PersonaCard from "../../assets/images/personaCardBlue.png";
import { RiTwitterFill } from "react-icons/ri";
import { useGlobalContext } from "../../useContext/contextApi";
import LoadingIcons from "react-loading-icons";
import { IoIosCheckmarkCircle } from "react-icons/io";
import BgPng from "../../assets/images/personaAnalysisBackground.png";
import Badge1 from "../../assets/images/badge1.png";
import Badge2 from "../../assets/images/badge2.png";
import Badge3 from "../../assets/images/badge3.png";

export default function PersonaResults() {
    const history = useHistory();
    const {
        vcTwitterAuthClick,
        vcQueryChecker,
        vcPending,
        vcBtnText,
        setVcBtnText,
        vcAnalysis,
        userAnalysisSearch,
    } = useGlobalContext();
    const [selectedTrait, setSelectedTrait] = useState({
        loving: [],
        involvedIn: [],
        curiousAbout: [],
        traitName: "",
    });
    const [canSearchOthers, setCanSearchOthers] = useState(false);
    const [showUsersDropdown, setShowUsersDropwdown] = useState(false);
    const elementToScroll = useRef(null);
    const [selectedUser, setSelectedUser] = useState({
        username: "",
        userId: "",
    });
    const [externalUsers, setExternalUsers] = useState([]);

    const executeScroll = () => elementToScroll.current.scrollIntoView();

    const handleDropdownClick = () => {
        console.log("accordion button clicked");
        setShowUsersDropwdown(!showUsersDropdown);
    };

    const selectUser = (user) => {
        console.log("selected user is =>", user);
        userAnalysisSearch(user.userId);
        setSelectedUser(user);
        setShowUsersDropwdown(!showUsersDropdown);
    };

    useEffect(() => {
        if (window.location.href.includes("?")) {
            const url = new URL(window.location.href);
            let taskId = url.searchParams.get("task_id");
            let username = url.searchParams.get("username");
            let canSearchOthers = url.searchParams.get("search");

            if (taskId !== null) {
                vcQueryChecker(taskId);
            }

            if (username !== null) {
                console.log("there is username and it is", username);
            }

            if (canSearchOthers) {
                setCanSearchOthers(canSearchOthers);
                fetch("https://api2.firstbatch.xyz/2/influencer-list")
                    .then((response) => response.json())
                    .then((data) => setExternalUsers(data.analysis));
            }

            if (taskId === "null") {
                setVcBtnText("RE-CONNECT");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let isAuthenticated = localStorage.getItem("isAuthenticated");
        if (!isAuthenticated) history.push("/demo-auth");
    }, [history]);

    useEffect(() => {
        if (vcAnalysis.originalData.length !== 0) {
            setSelectedTrait(vcAnalysis.originalData[0]);
            executeScroll();
        }
    }, [vcAnalysis]);

    return (
        <div className="personaAnalysisWrapper">
            <Navbar />
            <NavbarMobile />
            <div className="pageContent">
                <div className="resultsWrapper">
                    <div className="section1">
                        <div className="left">
                            <h1>Person-α</h1>
                            <h2>
                                Connect twıtter to see ınterests & personas
                                matchıng your socıal profıle
                            </h2>
                            {canSearchOthers ? (
                                <div className="dropdownBtn">
                                    <button onClick={handleDropdownClick}>
                                        {selectedUser.username
                                            ? selectedUser.username
                                            : "Select a User"}
                                    </button>
                                    <div
                                        className={
                                            showUsersDropdown
                                                ? "dropdown active"
                                                : "dropdown"
                                        }
                                    >
                                        {externalUsers.length !== 0 &&
                                            externalUsers.map((user, index) => {
                                                return (
                                                    <div
                                                        onClick={() =>
                                                            selectUser(user)
                                                        }
                                                        className="user"
                                                        key={`user${index}`}
                                                    >
                                                        <span>@</span>
                                                        <span>
                                                            {user.username}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            ) : (
                                <button onClick={vcTwitterAuthClick}>
                                    {vcPending ? (
                                        <LoadingIcons.Puff />
                                    ) : vcBtnText === "CONNECTED" ? (
                                        <span className="btnInner">
                                            <IoIosCheckmarkCircle />{" "}
                                            <span>{vcBtnText}</span>
                                        </span>
                                    ) : (
                                        <span className="btnInner">
                                            <RiTwitterFill />{" "}
                                            <span>{vcBtnText}</span>
                                        </span>
                                    )}
                                </button>
                            )}
                        </div>
                        <div className="right">
                            <img
                                src={PersonaCard}
                                alt="Persona Card Illustration"
                            />
                        </div>
                        <div className="gradient">
                            <img src={BgPng} alt="Background Gradient" />
                        </div>
                    </div>
                    <div className="scrollAnchor" ref={elementToScroll}></div>
                    {vcAnalysis.originalData.length !== 0 && (
                        <>
                            <div className="resultBox1">
                                <div className="card">
                                    <div className="label">
                                        <span>HIGH INTEREST</span>
                                        <div className="gradient"></div>
                                    </div>
                                    {vcAnalysis.lovingArr.length !== 0 ? (
                                        vcAnalysis.lovingArr.map(
                                            (interest, index) => {
                                                return (
                                                    <div
                                                        key={`lovingInterest${index}`}
                                                        className="interest"
                                                    >
                                                        {interest}
                                                    </div>
                                                );
                                            }
                                        )
                                    ) : (
                                        <div className="interest">
                                            There is no interest in this field
                                        </div>
                                    )}
                                </div>
                                <div className="textContainer">
                                    <h2 className="title">LOVING</h2>
                                    <h3 className="subtitle">
                                        Interests that your socıal profıle
                                        heavıly related ın multiple directıons.
                                    </h3>
                                </div>
                            </div>
                            <div className="resultBox2">
                                <div className="card">
                                    <div className="label">
                                        <span>MEDIUM INTEREST</span>
                                        <div className="gradient"></div>
                                    </div>
                                    {vcAnalysis.involvedInArr.length !== 0 ? (
                                        vcAnalysis.involvedInArr.map(
                                            (interest, index) => {
                                                return (
                                                    <div
                                                        key={`involvedInInterest${index}`}
                                                        className="interest"
                                                    >
                                                        {interest}
                                                    </div>
                                                );
                                            }
                                        )
                                    ) : (
                                        <div className="interest">
                                            There is no interest in this field
                                        </div>
                                    )}
                                </div>
                                <div className="textContainer">
                                    <h2 className="title">INVOLVED IN</h2>
                                    <h3 className="subtitle">
                                        ınterests that your socıal profıle ıs
                                        densely ınvolved ın.
                                    </h3>
                                </div>
                            </div>
                            <div className="resultBox3">
                                <div className="left">
                                    <h5 className="title">
                                        Select a persona to vıew ınterests
                                    </h5>
                                    <div className="selectionCard">
                                        <div className="cardTitle">Persona</div>
                                        {vcAnalysis.originalData.map(
                                            (trait, index) => {
                                                if (!trait.isEmpty) {
                                                    return (
                                                        <div
                                                            key={`trait${index}`}
                                                            onClick={() =>
                                                                setSelectedTrait(
                                                                    trait
                                                                )
                                                            }
                                                            className={
                                                                selectedTrait.traitName ===
                                                                trait.traitName
                                                                    ? "trait active"
                                                                    : "trait"
                                                            }
                                                        >
                                                            <span>
                                                                {
                                                                    trait.traitName
                                                                }
                                                            </span>
                                                        </div>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            }
                                        )}
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="lineContainer">
                                        <div className="line"></div>
                                        <div className="lineTitle">
                                            INTERESTS
                                        </div>
                                    </div>
                                    <h4 className="title">
                                        {selectedTrait.traitName}
                                    </h4>
                                    {selectedTrait.loving.length > 0 && (
                                        <div className="box box1">
                                            <div className="t1">
                                                <span className="orange">
                                                    LOVING
                                                </span>
                                                <img src={Badge1} alt="badge" />
                                            </div>
                                            {selectedTrait.loving.map(
                                                (interest, index) => {
                                                    return (
                                                        <div
                                                            key={`loving${index}`}
                                                            className="interest"
                                                        >
                                                            <div className="label">
                                                                {interest.label}
                                                            </div>
                                                            <div className="score orange">
                                                                {interest.score}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    )}
                                    {selectedTrait.involvedIn.length !== 0 && (
                                        <div className="box box2">
                                            <div className="t1">
                                                <span className="green">
                                                    INVOLVED IN
                                                </span>
                                                <img src={Badge2} alt="badge" />
                                            </div>
                                            {selectedTrait.involvedIn.map(
                                                (interest, index) => {
                                                    return (
                                                        <div
                                                            key={`involvedIn${index}`}
                                                            className="interest"
                                                        >
                                                            <div className="label">
                                                                {interest.label}
                                                            </div>
                                                            <div className="score green">
                                                                {interest.score}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    )}
                                    {selectedTrait.curiousAbout.length !==
                                        0 && (
                                        <div className="box box3">
                                            <div className="t1">
                                                <span className="blue">
                                                    CURIOUS ABOUT
                                                </span>
                                                <img src={Badge3} alt="badge" />
                                            </div>
                                            {selectedTrait.curiousAbout.map(
                                                (interest, index) => {
                                                    return (
                                                        <div
                                                            key={`curiousAboutMicro${index}`}
                                                            className="interest"
                                                        >
                                                            <div className="label">
                                                                {interest.label}
                                                            </div>
                                                            <div className="score blue">
                                                                {interest.score}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}
