import React from "react"
import { Link } from "react-router-dom"
import { ReactComponent as Copyright } from "../assets/images/copyright.svg"
import { RiTwitterFill } from "react-icons/ri"

export default function Footer() {
	return (
		<>
			<footer className='footerDesktop'>
				<div className='left'>
					<a rel='noreferrer' target='_blank' href='https://whitepaper.firstbatch.xyz/' className='link'>
						WHITEPAPER
					</a>
					<Link to='/persona' className='link'>
						PERSONA
					</Link>
					<div className='copyright'>
						<Copyright /> <span>2022</span>
					</div>
				</div>
				<div className='center'>
					<Link to='/' className='brand'>
						FirstBatch
					</Link>
				</div>
				<div className='right'>
					<div className='contact'>CONTACT</div>
					<span className='mail'>info@firstbatch.xyz</span>
					<div className='socials'>
						<a href='https://twitter.com/FirstBatchxyz_' rel='noreferrer' target='_blank'>
							<RiTwitterFill />
						</a>
					</div>
				</div>
			</footer>
		</>
	)
}
