import React, { useState, useEffect, useContext } from "react"

const chainId = "0x13881"
const chainName = "Mumbai Testnet"
const rpcUrls = ["https://matic-mumbai.chainstacklabs.com"]
const blockExplorerUrls = ["https://mumbai.polygonscan.com/"]
const symbol = "MATIC"

if (window.ethereum) {
	window.ethereum.on("chainChanged", _chainId => {
		if (_chainId !== chainId) {
			window.location.reload()
		}
	})
}

const MetamaskContext = React.createContext()
const MetamaskProvider = ({ children }) => {
	const [connectedAccount, setConnectedAccount] = useState(null)
	const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(false)

	useEffect(() => {
		if (typeof window.ethereum !== "undefined") {
			setIsMetamaskInstalled(true)
		}
	}, [])

	const metamaskBtnClick = async () => {
		try {
			await window.ethereum.request({
				method: "wallet_switchEthereumChain",
				params: [
					{
						chainId,
					},
				],
			})
			const accounts = await window.ethereum.request({ method: "eth_requestAccounts" })
			const account = accounts[0]
			setConnectedAccount(account)
		} catch (switchError) {
			// This error code indicates that the chain has not been added to MetaMask.
			if (switchError.code === 4902) {
				try {
					await window.ethereum.request({
						method: "wallet_addEthereumChain",
						params: [
							{
								chainId,
								chainName,
								rpcUrls,
								blockExplorerUrls,
								nativeCurrency: {
									name: "MATIC",
									symbol: "MATIC",
									decimals: 18,
								},
							},
						],
					})
					const accounts = await window.ethereum.request({ method: "eth_requestAccounts" })
					const account = accounts[0]
					setConnectedAccount(account)
				} catch (addError) {
					console.log("addError =>", addError)
				}
			}
			console.log("switchError =>", switchError)
		}
	}

	return (
		<>
			<MetamaskContext.Provider
				value={{
					connectedAccount,
					metamaskBtnClick,
					chainId,
					isMetamaskInstalled,
				}}
			>
				{children}
			</MetamaskContext.Provider>
		</>
	)
}

export const useGlobalContext = () => {
	return useContext(MetamaskContext)
}

export { MetamaskContext, MetamaskProvider }
