import React, { useEffect, useRef } from "react"
import { useGlobalContext } from "../../useContext/contextApi"
import Navbar from "../../components/Navbar.jsx"
import NavbarMobile from "../../components/NavbarMobile.jsx"
import Footer from "../../components/Footer.jsx"
import Circle1 from "../../assets/images/firstPlaceCircle.png"
import Circle2 from "../../assets/images/secondPlaceCircle.png"
import Circle3 from "../../assets/images/thirdPlaceCircle.png"
import Badge1 from "../../assets/images/firstPlaceBadge.png"
import Badge2 from "../../assets/images/secondPlaceBadge.png"
import Badge3 from "../../assets/images/thirdPlaceBadge.png"
import { RiTwitterFill } from "react-icons/ri"
import { IoIosCheckmarkCircle } from "react-icons/io"
import { FiStar } from "react-icons/fi"
import LoadingIcons from "react-loading-icons"

export default function Web3Persona() {
	const {
		setMessage,
		queryChecker,
		analyzeQuality,
		analysis,
		twitterAuthBtnText,
		isPending,
		isTwitterAuthBtnDisabled,
		message,
		web3TwitterAuthClick,
		tweetText,
	} = useGlobalContext()
	const elementToScroll = useRef(null)
	const executeScroll = () => elementToScroll.current.scrollIntoView()

	useEffect(() => {
		if (window.location.href.includes("?")) {
			const url = new URL(window.location.href)
			let taskId = url.searchParams.get("task_id")
			if (taskId !== "null") {
				queryChecker(taskId)
			}

			if (taskId === "null") {
				setMessage("User Declined Authorization")
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (analysis.length !== 0) {
			executeScroll()
		}
	}, [analysis])

	return (
		<div className='web3PersonaWrapper'>
			<Navbar />
			<NavbarMobile />
			<div className='pageContent'>
				<div className='titles'>
					<h1>
						where do you belong <br /> (in web3.0)
					</h1>
					<div className='linebreak'></div>
					<h2>Follow steps below to join early access. Explore your web 3.0 communities and share with your network.</h2>
				</div>
				<div className='buttons'>
					<h5 className='t1'>FOLLOW FIRSTBATCH</h5>
					<a href='https://twitter.com/FirstBatchxyz_' rel='noreferrer' target='_blank' className='b1 twitter-follow-button'>
						FOLLOW US
					</a>
					<script async src='https://platform.twitter.com/widgets.js' charSet='utf-8'></script>
					<h5 className='t2' ref={elementToScroll}>
						Analyze PROFILE
					</h5>
					<div className='secondButtonContainer'>
						<div className='l1'></div>
						<button
							onClick={web3TwitterAuthClick}
							className={isTwitterAuthBtnDisabled ? "b2 twitterAuthBtn disabled" : "b2 twitterAuthBtn"}
						>
							{isPending ? (
								<LoadingIcons.Puff />
							) : twitterAuthBtnText === "CONNECTED" ? (
								<span className='btnInner'>
									<IoIosCheckmarkCircle /> <span>{twitterAuthBtnText}</span>
								</span>
							) : (
								<span className='btnInner'>
									<RiTwitterFill /> <span>{twitterAuthBtnText}</span>
								</span>
							)}
						</button>
						<div className='l2'></div>
					</div>
					<p className='t3'>{message}</p>
					{analysis.length !== 0 && (
						<>
							<div className='stars'>
								<div className={analyzeQuality.score > 0 ? "s1 active" : "s1"}>
									<FiStar />
								</div>
								<div className={analyzeQuality.score > 1 ? "s2 active" : "s2"}>
									<FiStar />
								</div>
								<div className={analyzeQuality.score > 2 ? "s3 active" : "s3"}>
									<FiStar />
								</div>
							</div>
							<div className='cryptoTitle'>{analyzeQuality.str}</div>
						</>
					)}
					{/* *some accounts may not be eligible to receive tokens due to inactivity in twitter */}
					{analysis.length !== 0 && (
						<a
							className='twitter-share-button b3'
							rel='noreferrer'
							target='_blank'
							href={`https://twitter.com/intent/tweet?text=${tweetText}`}
						>
							SHARE
						</a>
					)}
				</div>

				<div className='results'>
					<div className='scrollAnchor'></div>
					{analysis.length !== 0 && (
						<div className='bottom bottom1'>
							<div className='line'></div>
							<div className='label'>You’re involved in</div>
						</div>
					)}
					{analysis.length !== 0 &&
						analysis.map((scope, index) => {
							return (
								<div key={`macro${index}`} className='resultRow'>
									<div className='top'>
										<div className='label'>
											<div className='index'>0{index + 1}</div>
											<div className='line'></div>
											<div className='title'>{scope.scope}</div>
										</div>
										<div className='output'>
											{scope.result.map((result, index) => {
												if (index === 0 && result.interest_rate !== "No Interest") {
													return (
														<div key={`micro${index}`} className='result'>
															<a href={result.twitter_url} rel='noreferrer' target='_blank' className='imgContainer'>
																<img className='circle' src={Circle1} alt='Circle' />
																<img className='badge' src={Badge1} alt='Badge' />
																<img className='formationImg' src={result.image_url} alt='' />
															</a>
															<div className='v1'>{result.formation}</div>
															{/* <div className='v2'>{result.interest_rate}</div> */}
														</div>
													)
												} else if (index === 1 && result.interest_rate !== "No Interest") {
													return (
														<div key={`micro${index}`} className='result'>
															<a href={result.twitter_url} rel='noreferrer' target='_blank' className='imgContainer'>
																<img className='circle' src={Circle2} alt='Circle' />
																<img className='badge' src={Badge2} alt='Badge' />
																<img className='formationImg' src={result.image_url} alt='' />
															</a>
															<div className='v1'>{result.formation}</div>
															{/* <div className='v2'>{result.interest_rate}</div> */}
														</div>
													)
												} else if (index === 2 && result.interest_rate !== "No Interest") {
													return (
														<div key={`micro${index}`} className='result'>
															<a href={result.twitter_url} rel='noreferrer' target='_blank' className='imgContainer'>
																<img className='circle' src={Circle3} alt='Circle' />
																<img className='badge' src={Badge3} alt='Badge' />
																<img className='formationImg' src={result.image_url} alt='' />
															</a>
															<div className='v1'>{result.formation}</div>
															{/* <div className='v2'>{result.interest_rate}</div> */}
														</div>
													)
												} else {
													return (
														<div
															key={`micro${index}`}
															className={result.interest_rate === "No Interest" ? "result faded" : "result"}
														>
															<a
																href={result.twitter_url}
																rel='noreferrer'
																target='_blank'
																className={
																	result.interest_rate === "No Interest"
																		? "imgContainer imgS sm"
																		: "imgContainer imgS"
																}
															>
																<img className='formationImg' src={result.image_url} alt='' />
															</a>
															{result.interest_rate !== "No Interest" && <div className='v1'>{result.formation}</div>}
														</div>
													)
												}
											})}
										</div>
										{scope.isAllEmpty && (
											<div className='isAllEmpty'>
												It seems your social profile isn’t linked with any communities in this area.
											</div>
										)}
									</div>
									{index === 0 && (
										<div className='bottom'>
											<div className='line'></div>
											<div className='label'>You belong to</div>
										</div>
									)}
									{index === 1 && (
										<div className='bottom'>
											<div className='line'></div>
											<div className='label'>You’re aligned with</div>
										</div>
									)}
									{index === 2 && (
										<div className='share'>
											<div className='l1'></div>
											<a
												rel='noreferrer'
												target='_blank'
												href={`https://twitter.com/intent/tweet?text=${tweetText}`}
												className='b2'
											>
												SHARE
											</a>
											<div className='l2'></div>
										</div>
									)}
								</div>
							)
						})}
				</div>
			</div>
			<Footer />
		</div>
	)
}
