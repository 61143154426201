import React from "react"

import Desktop from "./views/Desktop.jsx"
import Mobile from "./views/Mobile.jsx"

export default function Landing() {
	return (
		<div className='landingWrapper'>
			<Desktop />
			<Mobile />
		</div>
	)
}
