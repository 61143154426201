import React from "react"
import { Link } from "react-router-dom"
import Navbar from "../components/Navbar.jsx"
import NavbarMobile from "../components/NavbarMobile.jsx"
import Footer from "../components/Footer.jsx"
import { HiOutlineArrowNarrowLeft } from "react-icons/hi"

export default function NotExists() {
	return (
		<div className='notExistsWrapper'>
			<Navbar />
			<NavbarMobile />
			<div className='pageContent'>
				<h1>This Page Does Not Exists</h1>
				<Link to='/'>
					{HiOutlineArrowNarrowLeft}
					Go Back
				</Link>
			</div>
			<Footer />
		</div>
	)
}
