import { createRoot } from "react-dom/client"
import { AppProvider } from "./useContext/contextApi"
import { MetamaskProvider } from "./useContext/metamaskApi"
import App from "./App"
import "./scss/main.scss"

createRoot(document.getElementById("root")).render(
	<AppProvider>
		<MetamaskProvider>
			<App tab='Landing' />
		</MetamaskProvider>
	</AppProvider>
)
