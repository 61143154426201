import React from "react"
import { Link } from "react-router-dom"
import { ReactComponent as BrandLogo } from "../assets/images/brandLogo.svg"
import { RiTwitterFill } from "react-icons/ri"
import { AiFillLinkedin } from "react-icons/ai"

export default function Navbar() {
	return (
		<div className='navbarWrapper'>
			<div className='landingNavbar'>
				<Link to='/' className='left'>
					<BrandLogo />
					<span className='brandName'>FirstBatch</span>
				</Link>
				<div className='right'>
					<a rel='noreferrer' target='_blank' href='https://whitepaper.firstbatch.xyz/' className='link'>
						whitepaper
					</a>
					<Link to='/persona' className='link'>
						persona
					</Link>
					<a className='link' href='https://twitter.com/FirstBatchxyz_' rel='noreferrer' target='_blank'>
						<RiTwitterFill />
					</a>
					{/* <a className='link' href='https://www.linkedin.com/company/firstbatchxyz/' rel='noreferrer' target='_blank'>
						<AiFillLinkedin />
					</a> */}
				</div>
			</div>
		</div>
	)
}
