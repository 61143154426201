const personaTraits = [
	{
		interestLabel: "SPORTS FAN",
		traits: [
			{ traitExplanation: "LOVING", traitValue: 100, traitLabel: "GOLDEN STATE WARRIORS" },
			{ traitExplanation: "INVOLVED IN", traitValue: 100, traitLabel: "PREMIERE LEAGUE" },
			{ traitExplanation: "CURIOUS ABOUT", traitValue: 100, traitLabel: "WNBA" },
		],
		isOpen: true,
	},
	{
		interestLabel: "WEB3 BUILDER",
		traits: [
			{ traitExplanation: "LOVING", traitValue: 100, traitLabel: "POLYGON" },
			{ traitExplanation: "INVOLVED IN", traitValue: 50, traitLabel: "STARKNET" },
			{ traitExplanation: "CURIOUS ABOUT", traitValue: 25, traitLabel: "COMMUNITY BUILDING" },
		],
		isOpen: false,
	},
	{
		interestLabel: "GREEN LIVING",
		traits: [
			{ traitExplanation: "LOVING", traitValue: 100, traitLabel: "VEGAN FOOD" },
			{ traitExplanation: "INVOLVED IN", traitValue: 50, traitLabel: "SUSTAINABLE FASHION" },
			{ traitExplanation: "CURIOUS ABOUT", traitValue: 25, traitLabel: "REUSABILITY" },
		],
		isOpen: false,
	},
]

export default personaTraits
