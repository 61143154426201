import React from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import Landing from "./pages/landing/Landing.jsx"
import Persona from "./pages/persona/Persona.jsx"
import Web3Persona from "./pages/web3Persona/Web3Persona.jsx"
import PersonaMap from "./pages/personaMap/PersonaMap.jsx"
import ScrollToTop from "./scrollToTop"
import DemoAuth from "./pages/personalAnalysis/DemoAuth.jsx"
import AnalysisResults from "./pages/personalAnalysis/AnalysisResults.jsx"
import NftMinting from "./pages/nftMinting/NftMinting.jsx"
import NotExists from "./pages/NotExists.jsx"

const App = () => (
	<BrowserRouter>
		<>
			<ScrollToTop />
			<Switch>
				<Route path='/' exact component={Landing} />
				<Route path='/persona' exact component={Persona} />
				<Route path='/where-do-you-belong' exact component={Web3Persona} />
				<Route path='/my-map' exact component={PersonaMap} />
				<Route path='/demo-auth' exact component={DemoAuth} />
				<Route path='/persona-analysis' exact component={AnalysisResults} />
				<Route path='/nft-minting' exact component={NftMinting} />
				<Route path='*' exact component={NotExists} />
			</Switch>
		</>
	</BrowserRouter>
)

export default App
