import React, { useState, useContext } from "react";
import axios from "axios";
import twitterAuthVC from "../utilities/personaAnalysis/twitterAuth";
import twitterAuthWeb3 from "../utilities/web3Persona/twitterAuth";

const AppContext = React.createContext();
const AppProvider = ({ children }) => {
    const [isPending, setIsPending] = useState(false);
    const [message, setMessage] = useState("");
    const [twitterAuthBtnText, setTwitterAuthBtnText] = useState("CONNECT");
    const [isTwitterAuthBtnDisabled, setIsTwitterAuthBtnDisabled] =
        useState(false);
    const [analysis, setAnalysis] = useState([]);
    const [analyzeQuality, setAnalyzeQuality] = useState({});
    const [vcPending, setVcPending] = useState(false);
    const [vcBtnText, setVcBtnText] = useState("CONNECT");
    const [vcAnalysis, setVcAnalysis] = useState({ originalData: [] });
    const [myMap, setMyMap] = useState({ analysis: [] });
    const [tweetText, setTweetText] = useState("");

    const queryChecker = (queryStr) => {
        setIsPending(true);

        let response = false;

        const interval = setInterval(() => {
            intervalRequest();
        }, 5000);

        const intervalRequest = async () => {
            if (response) {
                clearInterval(interval);
                return;
            }

            axios
                .get(`https://api2.firstbatch.xyz/2/tasks/${queryStr}`)
                .then((res) => {
                    // console.log(res)
                    if (res.data.task_status !== "PENDING") {
                        if (
                            res.data.task_result.eligibility_rules
                                .account_eligibility &&
                            res.data.task_result.eligibility_rules
                                .following_firstbatch
                        ) {
                            setTwitterAuthBtnText("CONNECTED");
                            setIsTwitterAuthBtnDisabled(true);
                            setAnalyzeQuality({
                                score: res.data.task_result.quality,
                                str: res.data.task_result.quality_string,
                            });
                            res.data.task_result.analysis.forEach((scope) => {
                                let isResultExist = false;
                                scope.result.forEach((formation) => {
                                    if (
                                        formation.interest_rate !==
                                        "No Interest"
                                    )
                                        isResultExist = true;
                                });

                                if (!isResultExist) {
                                    scope.isAllEmpty = true;
                                } else {
                                    scope.isAllEmpty = false;
                                }
                            });
                            setTweetText(res.data.task_result.tweet);
                        } else if (
                            res.data.task_result.eligibility_rules
                                .account_eligibility &&
                            !res.data.task_result.eligibility_rules
                                .following_firstbatch
                        ) {
                            setTwitterAuthBtnText("RE-CONNECT");
                            setIsTwitterAuthBtnDisabled(false);
                        } else {
                            setTwitterAuthBtnText("CONNECT");
                            setIsTwitterAuthBtnDisabled(true);
                        }

                        if (
                            res.data.task_result.message ===
                            "You need to follow @firstbatchxyz_ to get early access"
                        ) {
                            res.data.task_result.message =
                                "You need to follow us to get early access";
                        }

                        setMessage(res.data.task_result.message);
                        response = true;
                        setAnalysis(res.data.task_result.analysis);
                        setIsPending(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    response = true;
                    setIsPending(false);
                });
        };
    };

    const vcTwitterAuthClick = () => {
        twitterAuthVC();
    };

    const vcQueryChecker = (taskId) => {
        let response = false;
        setVcPending(true);

        if (taskId === null) {
            setVcBtnText("RE-CONNECT");
            return;
        }

        const interval = setInterval(() => {
            intervalRequest();
        }, 5000);

        const intervalRequest = async () => {
            if (response) {
                clearInterval(interval);
                return;
            }

            axios
                .get(`https://api2.firstbatch.xyz/2/tasks/${taskId}`)
                .then((res) => {
                    if (res.data.task_status === "complete") {
                        response = true;
                        setVcPending(false);
                        setVcBtnText("CONNECTED");

                        let lovingArr = [];
                        let involvedInArr = [];

                        res.data.task_result.forEach((trait) => {
                            let isAllEmpty = true;
                            if (
                                trait.curiousAbout.length !== 0 ||
                                trait.loving.length !== 0 ||
                                trait.involvedIn.length !== 0
                            )
                                isAllEmpty = false;
                            isAllEmpty
                                ? (trait.isEmpty = isAllEmpty)
                                : (trait.isEmpty = isAllEmpty);
                            if (trait.loving.length !== 0)
                                trait.loving.forEach((interest) =>
                                    lovingArr.push(interest.label)
                                );
                            if (trait.involvedIn.length !== 0)
                                trait.involvedIn.forEach((interest) =>
                                    involvedInArr.push(interest.label)
                                );
                        });

                        let newObj = {
                            lovingArr,
                            involvedInArr,
                            originalData: res.data.task_result.filter(
                                (interest) => !interest.isEmpty
                            ),
                        };

                        setVcAnalysis(newObj);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setVcPending(false);
                });
        };
    };

    const web3TwitterAuthClick = () => {
        twitterAuthWeb3();
    };

    const web3QueryChecker = (uuid) => {
        setIsPending(true);
        axios
            .get(`https://api2.firstbatch.xyz/2/persona-map?uuid=${uuid}`)
            .then((res) => {
                res.data.analysis.forEach((scope) => {
                    let isResultExist = false;
                    scope.result.forEach((formation) => {
                        if (formation.interest_rate !== "No Interest")
                            isResultExist = true;

                        if (formation.formation === "Sandbox")
                            formation.image_url =
                                "https://pbs.twimg.com/profile_images/1531957614493450242/JJZuGNcX_400x400.png";
                    });

                    if (!isResultExist) {
                        scope.isAllEmpty = true;
                    } else {
                        scope.isAllEmpty = false;
                    }
                });
                setMyMap(res.data);
                setIsPending(false);
            })
            .catch((err) => {
                console.log(err);
                setIsPending(false);
            });
    };

    const userAnalysisSearch = (userId) => {
        axios
            .get(
                `https://api2.firstbatch.xyz/2/persona-analysis/search?id=${userId}`
            )
            .then((res) => {
                console.log(res.data.analysis);

                let lovingArr = [];
                let involvedInArr = [];

                res.data.analysis.forEach((trait) => {
                    let isAllEmpty = true;
                    if (
                        trait.curiousAbout.length !== 0 ||
                        trait.loving.length !== 0 ||
                        trait.involvedIn.length !== 0
                    )
                        isAllEmpty = false;
                    isAllEmpty
                        ? (trait.isEmpty = isAllEmpty)
                        : (trait.isEmpty = isAllEmpty);
                    if (trait.loving.length !== 0)
                        trait.loving.forEach((interest) =>
                            lovingArr.push(interest.label)
                        );
                    if (trait.involvedIn.length !== 0)
                        trait.involvedIn.forEach((interest) =>
                            involvedInArr.push(interest.label)
                        );
                });

                let newObj = {
                    lovingArr,
                    involvedInArr,
                    originalData: res.data.analysis.filter(
                        (interest) => !interest.isEmpty
                    ),
                };
                console.log(newObj);

                setVcAnalysis(newObj);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <AppContext.Provider
                value={{
                    queryChecker,
                    message,
                    isPending,
                    setMessage,
                    twitterAuthBtnText,
                    isTwitterAuthBtnDisabled,
                    analysis,
                    analyzeQuality,
                    vcTwitterAuthClick,
                    vcQueryChecker,
                    vcPending,
                    setVcPending,
                    vcBtnText,
                    setVcBtnText,
                    vcAnalysis,
                    setVcAnalysis,
                    web3TwitterAuthClick,
                    web3QueryChecker,
                    myMap,
                    tweetText,
                    userAnalysisSearch,
                }}
            >
                {children}
            </AppContext.Provider>
        </>
    );
};

export const useGlobalContext = () => {
    return useContext(AppContext);
};

export { AppContext, AppProvider };
