import React from "react"
import { Link } from "react-router-dom"
import Navbar from "../../../components/Navbar.jsx"
import Footer from "../../../components/Footer.jsx"
import { ReactComponent as FirstBatchWatermark } from "../../../assets/images/firstBatchWatermark.svg"
import BackgroundGradient2 from "../../../assets/images/landingBgGradient2.png"
import { ReactComponent as SectionTwoLine } from "../../../assets/images/sectionTwoLine.svg"
import { ReactComponent as Lock } from "../../../assets/images/lock.svg"
import { ReactComponent as APlatformIllustration1 } from "../../../assets/images/aPlatformIllustration1.svg"
import { ReactComponent as APlatformIllustration2 } from "../../../assets/images/aPlatformIllustration2.svg"
import PoiDiagram from "../../../assets/images/poiDiagram.png"
import { ReactComponent as Roadmap } from "../../../assets/images/roadmap.svg"
import interests from "../../../data/interests"
import members from "../../../data/members"
import Video from "../../../assets/images/firstbatch_persona card.mp4"
import TeamMember from "../../../components/TeamMember.jsx"
import PersonaCardPng from "../../../assets/images/personaCard.png"
import ReleaseSchedule from "../../../assets/images/releaseSchedule.png"
import ProofOfInterestPng from "../../../assets/images/proofOfInterest.png"
import PersonaCardBackgroundPng from "../../../assets/images/personaCardBackground.png"
import BelongToEarnPng from "../../../assets/images/belongToEarn.png"
import AlienWorlds from "../../../assets/images/alienWorlds.png"

export default function Desktop() {
	return (
		<main className='landingWrapperDesktop'>
			<Navbar />
			<div className='bgGradientTop'></div>
			<section className='watermark'>
				<FirstBatchWatermark />
			</section>
			<section className='sectionOne'>
				<div className='left'>
					<div className='textBox'>
						<div className='borderLeft'></div>
						<div className='borderRight'></div>
						<img src={ProofOfInterestPng} alt='Proof Of Interest' />
						<h3>FirstBatch is a protocol to prove and earn from your interest in anything.</h3>
					</div>
					<div className='buttonsContainer'>
						<Link to='/persona' className='btn1'>
							<span>EARLY ACCESS</span>
						</Link>
						<a className='btn2' rel='noreferrer' target='_blank' href='https://twitter.com/FirstBatchxyz_'>
							<span>FOLLOW US</span>
						</a>
					</div>
				</div>
				<div className='right'>
					<div className='imgBox'>
						<video width='100%' height='100%' preload='auto' autoPlay muted loop controls={false}>
							<source src={Video} type='video/mp4' />
							Your browser does not support HTML5 video.
						</video>
					</div>
				</div>
			</section>
			<h2 className='lt1'>Your identity is a self-representation of your interests, relationships, social activity and much more</h2>
			<h2 className='lt2'>FirstBatch utilizes A.I to create a representation of your identity from your social data</h2>
			<section className='sectionTwo'>
				<div className='interestsBelt'>
					{interests.map((interest, index) => {
						return <span key={index}>{interest.interest}</span>
					})}
				</div>
				<div className='bgLayer'>
					<img src={PersonaCardBackgroundPng} alt='Persona Card Background' />
				</div>
				<div className='left'>
					<div className='imgBox'>
						<img src={PersonaCardPng} alt='Persona Card Illustration' />
					</div>
				</div>
				<div className='right'>
					<div className='top title'>
						<h3 className='line1'>A NEW</h3>
						<h3 className='line2'>CYBER IDENTITY MODEL</h3>
						<h3 className='line3'>FOR THE WEB 3.0</h3>
					</div>
					<div className='bottom'>
						<div className='imgBox'>
							<SectionTwoLine />
						</div>
						<h3 className='title'>
							Your <span className='bold'>Interests Mapped</span> Into <span className='bold'>A Soulbond NFT</span>
						</h3>
						<Link to='/persona' className='moreBtn'>
							<span>MORE</span>
						</Link>
					</div>
				</div>
			</section>
			<section className='sectionThree'>
				<div className='borderLeft'></div>
				<div className='borderRight'></div>
				<img src={BelongToEarnPng} alt='Belong To Earn' />
				<div className='subtitle'>prove you belong to communities with POI to earn $BATCH</div>
			</section>
			<section className='sectionFour'>
				<img src={PoiDiagram} alt='PoiDiagram' className='poiDiagram' />
			</section>
			<section className='sectionFive'>
				<div className='label'>
					<div className='innerWrapper'>
						<div className='line'></div>
						<span>Interest-Gated Web3</span>
					</div>
				</div>
				<div className='contentBox1'>
					<div className='left'>
						<div className='listLabel'>
							<span>FIRSTBATCH</span>
							<span>
								unlocks <Lock />
							</span>
						</div>
						<div className='list'>
							<div className='listItem'>
								<div className='dot'></div>
								<span>memberships</span>
							</div>
							<div className='listItem'>
								<div className='dot'></div>
								<span>events</span>
							</div>
							<div className='listItem'>
								<div className='dot'></div>
								<span>content</span>
							</div>
							<div className='listItem'>
								<div className='dot'></div>
								<span>airdrops & bounties</span>
							</div>
						</div>
					</div>
					<div className='right'>
						{/* <ProofOfInterest /> */}
						<img src={AlienWorlds} alt='Proof of Interest' />
					</div>
				</div>
				<div className='label label2'>
					<div className='innerWrapper'>
						<div className='line'></div>
						<span>a Platform</span>
					</div>
				</div>
				<div className='contentBox2'>
					<div className='top'>
						<div className='left'>
							<div className='boxLabel'>FOR</div>
							<div className='box'>
								<div className='borderLeft'></div>
								<div className='borderRight'></div>
								<span className='underline'>personalized navigation</span>
								<span>within web3 ecosystem</span>
							</div>
						</div>
						<div className='right'>
							<APlatformIllustration1 />
						</div>
					</div>
					<div className='bottom'>
						<div className='left'>
							<div className='boxLabel'>WHERE</div>
							<div className='box'>
								<div className='borderLeft'></div>
								<div className='borderRight'></div>
								<span>Protocols, Collectors, DApps,</span>
								<span>DAOs, Socials</span>
								<span className='underline'>meet Web3 users</span>
							</div>
						</div>
						<div className='right'>
							<APlatformIllustration2 />
						</div>
					</div>
				</div>
			</section>
			<section className='sectionSix'>
				<div className='row'>
					<div className='box'>
						<div className='borderLeft'></div>
						<div className='borderRight'></div>
						<span>01</span>
					</div>
					<div className='text'>
						<span className='bold'>Personalized navigation</span> within web3 ecosystem
					</div>
				</div>
				<div className='row'>
					<div className='box'>
						<div className='borderLeft'></div>
						<div className='borderRight'></div>
						<span>02</span>
					</div>
					<div className='text'>
						Keeping up with <span className='bold'>your favorite dApps and DAOs</span>
					</div>
				</div>
				<div className='row'>
					<div className='box'>
						<div className='borderLeft'></div>
						<div className='borderRight'></div>
						<span>03</span>
					</div>
					<div className='text'>
						<span className='bold'>Earning By Belonging</span> to ideas, communities
					</div>
				</div>
			</section>
			<section className='sectionSeven'>
				{/* <Explanations /> */}
				<div className='row'>
					<div className='lineTop'></div>
					<div className='contentBox'>
						<div className='label'>Web2 & Web3</div>
						<div className='filler'></div>
						<div className='dot'></div>
						<div className='value'>Web2 data for web3 identity. FirstBatch allows monetization of web2 data</div>
					</div>
				</div>
				<div className='row'>
					<div className='lineTop'></div>
					<div className='contentBox'>
						<div className='label'>Privacy & Trustless</div>
						<div className='filler'></div>
						<div className='dot'></div>
						<div className='value'>FIRSTBATCH is a privacy preserving protocol. Users don’t share share their data with the protocol</div>
					</div>
				</div>
				<div className='row'>
					<div className='lineTop'></div>
					<div className='lineBottom'></div>
					<div className='contentBox'>
						<div className='label'>$BATCH</div>
						<div className='filler'></div>
						<div className='dot'></div>
						<div className='value'>BATCH token is the currency for incentives in FirstBatch</div>
					</div>
				</div>
			</section>
			<section className='sectionEight'>
				<div className='label'>
					<div className='line'></div>
					<span>Roadmap</span>
					<div className='line2'></div>
				</div>
				<Roadmap />
				<div className='bottomLine'></div>
			</section>
			<section className='sectionNine'>
				<div className='bgGradient2'>
					<img src={BackgroundGradient2} alt='bgGradient' />
				</div>
				<div className='label'>
					<div className='innerWrapper'>
						<div className='line'></div>
						<span>Team</span>
					</div>
				</div>
				<div className='teamMembers'>
					{members.map((member, index) => {
						return <TeamMember key={index} {...member} />
					})}
				</div>
			</section>
			<section className='sectionTen'>
				<div className='label'>
					<div className='innerWrapper'>
						<div className='line'></div>
						<span>Tokenomics</span>
					</div>
				</div>
				<div className='text'>
					BATCH creates a well-balanced Belong-to-Earn economics model that could offer a pleasant monetary reward system to Persona Token
					Holders and enables dAPPs, DAOs, and brands to take advantage of interest gated interactions with the blockchain while presenting
					a fair and robust voting and DAO mechanism.
				</div>
				<div className='infos'>
					<div className='row'>
						<div className='info'>
							<div className='infoLabel'>Symbol</div>
							<div className='value'>BATCH</div>
						</div>
						<div className='info'>
							<div className='infoLabel'>Tokens Offered In Public Sale</div>
							<div className='value'>50,000,000</div>
						</div>
					</div>
					<div className='row mt'>
						<div className='info'>
							<div className='infoLabel'>Initial Public Sale Price</div>
							<div className='value'>$0.15</div>
						</div>
						<div className='info'>
							<div className='infoLabel'>Total Supply</div>
							<div className='value'>1,000,000,000</div>
						</div>
					</div>
				</div>
				<div className='scheduleImgContainer'>
					<img className='scheduleImg' src={ReleaseSchedule} alt='TOKEN RELEASE SCHEDULE' />
				</div>
			</section>
			<Footer />
		</main>
	)
}
