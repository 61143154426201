import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Navbar from "../../components/Navbar.jsx"
import NavbarMobile from "../../components/NavbarMobile.jsx"
import Footer from "../../components/Footer.jsx"
import { HiOutlineArrowNarrowLeft } from "react-icons/hi"
import { RiTwitterFill, RiRedditFill } from "react-icons/ri"
import { useGlobalContext } from "../../useContext/metamaskApi"
import MetamaskPng from "../../assets/images/metamask.png"

export default function NftMinting() {
	const { connectedAccount, metamaskBtnClick, isMetamaskInstalled } = useGlobalContext()

	return (
		<div className='nftMintingWrapper'>
			<Navbar />
			<NavbarMobile />
			<div className='pageContent'>
				{isMetamaskInstalled ? (
					<>
						{connectedAccount === null && (
							<button onClick={metamaskBtnClick}>
								<img src={MetamaskPng} alt='Metamask Logo' />
								METAMASK
							</button>
						)}
						{connectedAccount !== null && (
							<div className='socialMedia'>
								<h2>Your connected wallet id is: {connectedAccount}</h2>
								<h1>Please choose your persona input</h1>
								<div className='buttons'>
									<button className='twitter'>
										<RiTwitterFill /> TWITTER
									</button>
									<button className='reddit'>
										<RiRedditFill /> REDDIT
									</button>
								</div>
							</div>
						)}
					</>
				) : (
					<>
						<h1>Please Install Metamask to continue</h1>
					</>
				)}
			</div>
			<Footer />
		</div>
	)
}
