import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { ReactComponent as BrandLogoMobile } from "../assets/images/mobileLogo.svg"
import { ReactComponent as WatermarkMobile } from "../assets/images/firstBatchWatermarkMobile.svg"
import { ReactComponent as MenuLine } from "../assets/images/menuIcon.svg"
import { RiTwitterFill } from "react-icons/ri"
import { AiFillLinkedin } from "react-icons/ai"

export default function NavbarMobile() {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	const menuBtnClickHandler = () => {
		const body = document.querySelector("body")
		setIsMenuOpen(!isMenuOpen)
		isMenuOpen ? (body.style.overflow = "visible") : (body.style.overflow = "hidden")
	}

	useEffect(() => {
		return () => {
			const body = document.querySelector("body")
			body.style.overflow = "visible"
		}
	}, [])

	return (
		<div className='navbarMobile'>
			<div className='navRow'>
				<Link to='/' className='left'>
					<BrandLogoMobile />
					<span className='brandName'>FirstBatch</span>
				</Link>
				<div className={`${isMenuOpen ? "right active" : "right"}`}>
					<MenuLine onClick={menuBtnClickHandler} />
				</div>
			</div>
			{/* <div className='watermark'>
				<WatermarkMobile />
			</div> */}
			<div className={`${isMenuOpen ? "bgLayer bgLayerShow" : "bgLayer"}`}></div>
			<div className={`${isMenuOpen ? "offcanvasWrapper show" : "offcanvasWrapper"}`}>
				<div className='offcanvasMenu'>
					<div className='links'>
						<a rel='noreferrer' target='_blank' href='https://whitepaper.firstbatch.xyz/' className='link'>
							whitepaper
						</a>
						<Link to='/persona' className='link'>
							persona
						</Link>
						{/* <div className='link'>roadmap</div> */}
					</div>
					<div className='socials'>
						<a href='https://twitter.com/FirstBatchxyz_' rel='noreferrer' target='_blank'>
							<RiTwitterFill />
						</a>
						{/* <a href='https://www.linkedin.com/company/firstbatchxyz/' rel='noreferrer' target='_blank'>
							<AiFillLinkedin />
						</a> */}
					</div>
				</div>
			</div>
		</div>
	)
}
