import React, { useState, useEffect } from "react"

export default function PersonaTraits({ traits }) {
	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		if (traits.isOpen) {
			setIsOpen(true)
		}
	}, [traits.isOpen])

	return (
		<div className={`${isOpen ? "personaTrait personaTraitExpanded" : "personaTrait"}`}>
			<div className='accordionContainer'>
				<div className='top' onClick={() => setIsOpen(!isOpen)}>
					{traits.interestLabel}
				</div>
				<div className='bottom'>
					{traits.traits.map((trait, index) => {
						return (
							<div key={index} className='traitRow'>
								<div className='explanation'>{trait.traitExplanation}</div>
								<div className='value'>
									<div className='percentageContainer' style={{ width: trait.traitValue + "%" }}></div>
								</div>
								<div className='label'>{trait.traitLabel}</div>
							</div>
						)
					})}
				</div>
				<div className='lineContainer'>
					<div className='label'>INTERESTS</div>
				</div>
			</div>
		</div>
	)
}
