import React, { useState } from "react"
import Navbar from "../../components/Navbar.jsx"
import NavbarMobile from "../../components/NavbarMobile.jsx"
import Footer from "../../components/Footer.jsx"
import BgGradient1 from "../../assets/images/personaCardBackground.png"
import axios from "axios"
import { useHistory } from "react-router-dom"

export default function DemoAuth() {
	const history = useHistory()
	const [userToken, setUserToken] = useState("")

	const loginClickHandler = e => {
		e.preventDefault()
		if (!userToken) return

		axios
			.get(`https://api2.firstbatch.xyz/2/demo_auth?code=${userToken}`)
			.then(res => {
				if (res.data.success) {
					localStorage.setItem("isAuthenticated", true)
					history.goBack()
				}
			})
			.catch(err => {
				console.log(err.response)
			})
	}

	return (
		<div className='personaAnalysisWrapper'>
			<Navbar />
			<NavbarMobile />
			<div className='pageContent'>
				<div className='loginWrapper'>
					<div className='gradient'>
						<img src={BgGradient1} alt='Background Gradient' />
					</div>
					<form>
						<h1>Persona-α</h1>
						<h2>early access for persona analysıs</h2>
						{/* <input className='input1' required placeholder='username' type='text' /> */}
						<input value={userToken} onChange={e => setUserToken(e.target.value)} className='input' placeholder='code' type='text' />
						<button onClick={loginClickHandler}>LOGIN</button>
					</form>
				</div>
			</div>
			<Footer />
		</div>
	)
}
