import React from "react"
import { RiTwitterFill } from "react-icons/ri"
import { AiFillLinkedin } from "react-icons/ai"

export default function TeamMember(member) {
	return (
		<div className='member'>
			<div className='photo'>
				<img alt='member' src={process.env.PUBLIC_URL + `/members/${member.name}.png`} />
			</div>
			<div className='name'>{member.name}</div>
			<div className='bottom'>
				<div className={`${member.name === "Tugce Ergul" ? "title tugceErgulTitle" : "title"}`}>{member.title}</div>
				<div className='socials'>
					{member.twitter !== "" && (
						<a rel='noreferrer' target='_blank' href={member.twitter}>
							<RiTwitterFill />
						</a>
					)}
					{member.linkedin !== "" && (
						<a rel='noreferrer' target='_blank' href={member.linkedin}>
							<AiFillLinkedin />
						</a>
					)}
				</div>
			</div>
		</div>
	)
}
