const interests = [
    {
        interest: "Chicago Bulls",
    },
    {
        interest: "FTX",
    },
    {
        interest: "Meebits",
    },
    {
        interest: "Electric Vehicles",
    },
    {
        interest: "Homeschooling",
    },
    {
        interest: "K-Pop",
    },
    {
        interest: "Open Source",
    },
    {
        interest: "Moonbirds",
    },
    {
        interest: "Pad Thai",
    },
    {
        interest: "Free Britney",
    },
    {
        interest: "Zero Knowledge Proofs",
    },
    {
        interest: "Nicky Romero",
    },
    {
        interest: "Mediterranean Tours",
    }
]

export default interests
