import React from "react"
import NavbarMobile from "../../../components/NavbarMobile.jsx"
import Footer from "../../../components/Footer.jsx"
import PersonaCardDetailedMobile from "../../../assets/images/personaCardDetailedMobile.png"
import PersonaTrait from "../../../components/PersonaTrait.jsx"
import personaTraits from "../../../data/personaTraits"
import { useGlobalContext } from "../../../useContext/contextApi"
import { RiTwitterFill } from "react-icons/ri"
import LoadingIcons from "react-loading-icons"
import PersonaBgGradient from "../../../assets/images/personaCardBgMobile.png"
import { IoIosCheckmarkCircle } from "react-icons/io"

export default function Mobile() {
	const { message, isPending, twitterAuthBtnText, isTwitterAuthBtnDisabled } = useGlobalContext()

	return (
		<main className='personaWrapperMobile'>
			<NavbarMobile />
			<section className='s1'>
				<h1>early access</h1>
				<h3>Early access is closed for now. Persona NFT minting will be announced.</h3>
			</section>
			<section className='s2'>
				<div className='step1'>
					<div className='textRow'>
						<div className='index'>01</div>
						<div className='dot'></div>
						<div className='text'>FOLLOW FIRSTBATCH</div>
					</div>
					<a rel='noreferrer' target='_blank' href='https://twitter.com/FirstBatchxyz_' className='btn'>
						FOLLOW US
					</a>
				</div>
				<div className='step2'>
					<div className='textRow'>
						<div className='index'>02</div>
						<div className='dot'></div>
						<div className='text'>CONNECT TWITTER</div>
					</div>
					<div className={isTwitterAuthBtnDisabled ? "btn disabled" : "btn disabled"}>
						{isPending ? (
							<LoadingIcons.Puff />
						) : message !== "" ? (
							<span className='btnInner'>
								<IoIosCheckmarkCircle /> <span>{twitterAuthBtnText}</span>
							</span>
						) : (
							<span className='btnInner'>
								<RiTwitterFill /> <span>{twitterAuthBtnText}</span>
							</span>
						)}
					</div>
				</div>
				<div className='infoBox'>
					<span>{message}</span>
				</div>
				<div className='toBeAnnounced'>
					<div className='label'>TO BE ANNOUNCED</div>
					<div className='step3'>
						<div className='textRow'>
							<div className='index'>03</div>
							<div className='dot'></div>
							<div className='text'>MINT PERSONA NFT (FREE)</div>
						</div>
						<div className='btn'>MINT</div>
					</div>
					<div className='step4'>
						<div className='textRow'>
							<div className='index'>04</div>
							<div className='dot'></div>
							<div className='text'>RECEIVE 100 $BATCH</div>
						</div>
						<div className='btn'>CLAIM</div>
					</div>
				</div>
			</section>
			<div className='personaImgBox'>
				<img className='personaCard' src={PersonaCardDetailedMobile} alt='Persona Card Detailed Mobile' />
				<div className='bgLayerMobile'>
					<img src={PersonaBgGradient} alt='' />
				</div>
			</div>
			<section className='s3'>
				{personaTraits.map((trait, index) => {
					return <PersonaTrait key={index} traits={trait} />
				})}
			</section>
			<Footer />
		</main>
	)
}
