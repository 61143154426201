const members = [
	{
		name: "Kerim Kaya",
		title: "CEO",
		twitter: "https://twitter.com/kerimrocks",
		linkedin: "https://www.linkedin.com/in/kerim-kaya-552878129/",
		profile_photo: "",
	},
	{
		name: "Omer Kaya",
		title: "CTO",
		twitter: "https://twitter.com/kayaomers",
		linkedin: "https://www.linkedin.com/in/ömer-kaya-2056a7168/",
		profile_photo: "",
	},
	{
		name: "Efe Bulduk",
		title: "CSO",
		twitter: "https://twitter.com/TheBullduck",
		linkedin: "https://www.linkedin.com/in/efe-bulduk-61317047/",
		profile_photo: "",
	},
	{
		name: "Sam Lang",
		title: "Advisor",
		twitter: "",
		linkedin: "https://www.linkedin.com/in/langes/",
		profile_photo: "",
	},
	{
		name: "Tugce Ergul",
		title: "Advisor, Founder of Angel Labs",
		twitter: "https://twitter.com/tugceergul",
		linkedin: "https://www.linkedin.com/in/tugceergul/",
		profile_photo: "",
	},
	{
		name: "Anil Altuner",
		title: "Data Scientist",
		twitter: "https://twitter.com/anilaltuner",
		linkedin: "https://www.linkedin.com/in/anılberkaltuner/",
		profile_photo: "",
	},
	{
		name: "Mervenur Akyurek",
		title: "Content Strategist",
		twitter: "https://twitter.com/MervenAkyurekk",
		linkedin: "https://www.linkedin.com/in/merve-nur-akyürek-m1605/",
		profile_photo: "",
	},
	{
		name: "Doruk Ozer",
		title: "Full Stack Developer",
		twitter: "",
		linkedin: "https://www.linkedin.com/in/dorukozer/",
		profile_photo: "",
	},
	{
		name: "Ahmet Balaban",
		title: "Blockchain Engineer",
		twitter: "",
		linkedin: "https://www.linkedin.com/in/balabanaf/",
		profile_photo: "",
	},
	{
		name: "Ozgur Kuskonmaz",
		title: "Data Engineer",
		twitter: "",
		linkedin: "https://www.linkedin.com/in/ozgurkuskonmaz/",
		profile_photo: "",
	},
	{
		name: "Ethan Champagne",
		title: "Partnership Strategist", 
		twitter: "https://twitter.com/ethanpchamp",
		linkedin: "https://www.linkedin.com/in/ethan-champagne-13163372/",
		profile_photo: "",
	},
	{
		name: "Ece Gokmenoglu",
		title: "Lead Designer",
		twitter: "",
		linkedin: "https://www.linkedin.com/in/ece-gökmenoğlu-75a3aa140/",
		profile_photo: "",
	},
	{
		name: "Onur Durna",
		title: "Lead Designer",
		twitter: "",
		linkedin: "https://www.linkedin.com/in/mustafa-onur-durna-a72b67136/",
		profile_photo: "",
	},
]

export default members
