import React, { useState } from "react"
import { Link } from "react-router-dom"
import NavbarMobile from "../../../components/NavbarMobile.jsx"
import Footer from "../../../components/Footer.jsx"
import PersonaCardPng from "../../../assets/images/personaCard.png"
import PoiDiagram from "../../../assets/images/poiDiagram.png"
import ProofOfInterestMobile from "../../../assets/images/proofOfInterestMobile.png"
import SuggestionExample from "../../../assets/images/suggestionsExample.png"
import Notifications from "../../../assets/images/notifications.png"
import { ReactComponent as RoadmapMobile } from "../../../assets/images/roadmapMobile.svg"
import { ReactComponent as LockMobile } from "../../../assets/images/lockMobile.svg"
import interests from "../../../data/interests"
import TeamMember from "../../../components/TeamMember.jsx"
import members from "../../../data/members"
import AlienWorlds from "../../../assets/images/alienWorlds.png"
import PersonaBluePng from "../../../assets/images/personaCardBlue.png"
import MobileBgImg from "../../../assets/images/mobileBackground.png"
import BelongToEarn from "../../../assets/images/belongToEarn.png"
import ReleaseSchedule from "../../../assets/images/releaseSchedule.png"
import { ReactComponent as Watermark } from "../../../assets/images/firstBatchWatermarkMobile.svg"

export default function Mobile() {
	const [memberRenderLimit, setMemberRenderLimit] = useState(6)
	const [membersBtnText, setMembersBtnText] = useState("View More")

	const viewMore = () => {
		if (memberRenderLimit > 6) {
			setMemberRenderLimit(6)
			setMembersBtnText("View More")
		} else {
			setMemberRenderLimit(members.length)
			setMembersBtnText("View Less")
		}
	}

	return (
		<main className='landingWrapperMobile'>
			<NavbarMobile />
			<section className='s1'>
				<div className='watermark'>
					<Watermark />
				</div>
				<img src={PersonaBluePng} alt='Persona Card' />
				<div className='textBox'>
					<div className='borderLeft'></div>
					<div className='borderRight'></div>
					<img src={ProofOfInterestMobile} alt='Proof Of Interest' />
				</div>
				<h3 className='heroText'>FirstBatch is a protocol to prove and earn from your interest in anything.</h3>
				<Link to='/persona' className='btn1'>
					<span>EARLY ACCESS</span>
				</Link>
				<a className='btn2' rel='noreferrer' target='_blank' href='https://twitter.com/FirstBatchxyz_'>
					<span>FOLLOW US</span>
				</a>
			</section>
			<section className='s2'>
				<div className='title'>
					<h3 className='line1'>A NEW</h3>
					<h3 className='line2'>CYBER IDENTITY MODEL</h3>
					<h3 className='line3'>FOR THE WEB 3.0</h3>
				</div>
				<div className='personaCardContainer'>
					<div className='interestsBelt'>
						{interests.map((interest, index) => {
							return <span key={index}>{interest.interest}</span>
						})}
					</div>
					<div className='personaCard'>
						<img src={PersonaCardPng} alt='Persona Card' />
					</div>
					<div className='lineGradient'></div>
				</div>
				<h4 className='personaCardTitle'>
					Your <span className='bold'>Interests Mapped</span> Into <span className='bold'>A Soulbond NFT</span>
				</h4>
				<Link to='/persona' className='btn'>
					<span>MORE</span>
				</Link>
			</section>
			<section className='s3'>
				<div className='textBox'>
					<div className='borderLeft'></div>
					<div className='borderRight'></div>
					<img src={BelongToEarn} alt='Belong To Earn' />
					<span className='t2'>prove you belong to communities with POI to earn $BATCH</span>
				</div>
				<img src={PoiDiagram} alt='Poi Diagram' />
			</section>
			<section className='s4'>
				<div className='labelRow'>
					<div className='line'></div>
					<h2>Interest-Gated Web3</h2>
				</div>
				<div className='t1'>
					FIRSTBATCH <span className='underline'> unlocks:</span>
					<LockMobile />
				</div>
				<div className='list'>
					<div className='listItem'>
						<div className='dot'></div>
						<span>memberships</span>
					</div>
					<div className='listItem'>
						<div className='dot'></div>
						<span>events</span>
					</div>
					<div className='listItem'>
						<div className='dot'></div>
						<span>content</span>
					</div>
					<div className='listItem'>
						<div className='dot'></div>
						<span>airdrops & bounties</span>
					</div>
				</div>
				<img className='proofOfInterestMobile' src={AlienWorlds} alt='Proof Of Interest Example' />
			</section>
			<section className='s5'>
				<div className='labelRow'>
					<div className='line'></div>
					<h2>a Platform</h2>
				</div>
				<div className='textBoxWrapper'>
					<div className='label'>FOR</div>
					<div className='textBox'>
						<div className='borderLeft'></div>
						<div className='borderRight'></div>
						<h4>
							<span className='underline'>personalized navigation</span>
							<span>within web3 ecosystem</span>
						</h4>
					</div>
				</div>
				<img src={SuggestionExample} className='notificationsImg' alt='Suggestion Examples Illustration' />
				<div className='textBoxWrapper'>
					<div className='label'>WHERE</div>
					<div className='textBox textBox2'>
						<div className='borderLeft'></div>
						<div className='borderRight'></div>
						<h4>
							<span>
								Protocols, Collectors, DApps, DAO’s, Socials <span className='underline'>meet Web3 users meet Web3 users</span>
							</span>
						</h4>
					</div>
				</div>
				<img src={Notifications} className='notificationsImg' alt='Notifications Illustration' />
			</section>
			<section className='s6'>
				<div className='listItem'>
					<span className='index'>01</span>
					<span className='text'>
						<span className='bold'>Personalized navigation</span> within web3 ecosystem
					</span>
				</div>
				<div className='listItem'>
					<span className='index'>02</span>
					<span className='text'>
						Keeping up with <span className='bold'>your favorite dApps and DAOs</span>
					</span>
				</div>
				<div className='listItem'>
					<span className='index'>03</span>
					<span className='text'>
						<span className='bold'>Earning by Belonging</span> to ideas, communities
					</span>
				</div>
			</section>
			<section className='s7'>
				<div className='row'>
					<div className='borderTop'></div>
					<div className='contentBox'>
						<div className='textBox'>
							<div className='label'>Web2 & Web3</div>
							<div className='value'>Web2 data for web3 identity, detailed explanation for usage of web2 data</div>
						</div>
						<div className='dot'></div>
					</div>
				</div>
				<div className='row'>
					<div className='borderTop'></div>
					<div className='contentBox'>
						<div className='textBox'>
							<div className='label'>Privacy& Trustless</div>
							<div className='value'>FIRSTBATCH is privacy first. Users don’t share share their data with the protocol</div>
						</div>
						<div className='dot'></div>
					</div>
				</div>
				<div className='row'>
					<div className='borderTop'></div>
					<div className='borderBottom'></div>
					<div className='contentBox'>
						<div className='textBox'>
							<div className='label'>$BATCH</div>
							<div className='value'>BATCH token is the currency for incentives in FirstBatch</div>
						</div>
						<div className='dot'></div>
					</div>
				</div>
			</section>
			<section className='s8'>
				<div className='label'>
					<div className='line'></div>
					<span>Roadmap</span>
				</div>
				<RoadmapMobile />
			</section>
			<section className='teamMembersMobile'>
				<div className='labelRow'>
					<div className='line'></div>
					<h2>Core Team & Advisors</h2>
					<div className='bgGradientMobileContainer'>
						<img src={MobileBgImg} alt='background gradient' />
					</div>
				</div>
				<div className='membersContainer'>
					{members.slice(0, memberRenderLimit).map((member, index) => {
						return <TeamMember key={index} {...member} />
					})}
				</div>
				<div className='viewMoreBtn' onClick={viewMore}>
					{membersBtnText}
				</div>
			</section>
			<section className='s9'>
				<div className='labelRow'>
					<div className='line'></div>
					<h2>Tokenomics</h2>
				</div>
				<p>
					BATCH creates a well-balanced Belong-to-Earn economics model that could offer a pleasant monetary reward system to Persona Token
					Holders and enables dAPPs, DAOs, and brands to take advantage of interest gated interactions with the blockchain while presenting
					a fair and robust voting and DAO mechanism.
				</p>
				<div className='infoBox'>
					<div className='top'>
						<div className='left'>
							<div className='label'>Symbol</div>
							<div className='value'>BATCH</div>
						</div>
						<div className='right'>
							<div className='label'>Tokens Offered In Public Sale</div>
							<div className='value'>50,000,000</div>
						</div>
					</div>
					<div className='bottom'>
						<div className='left'>
							<div className='label'>Initial Public Sale Price</div>
							<div className='value'>$0.15</div>
						</div>
						<div className='right'>
							<div className='label'>Total Supply</div>
							<div className='value'>1,000,000,000</div>
						</div>
					</div>
				</div>
				<img className='relaseScheduleMobile' src={ReleaseSchedule} alt='Relase Schedule Diagram' />
			</section>
			<Footer />
		</main>
	)
}
