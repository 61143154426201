import React from "react"
import Navbar from "../../../components/Navbar.jsx"
import Footer from "../../../components/Footer.jsx"
import { ReactComponent as PersonaCardDetailed } from "../../../assets/images/personaCardDetailed.svg"
import BgGradient from "../../../assets/images/personaPageBgGradient.png"
import PersonaTrait from "../../../components/PersonaTrait.jsx"
import personaTraits from "../../../data/personaTraits"
import { RiTwitterFill } from "react-icons/ri"
import { IoIosCheckmarkCircle } from "react-icons/io"
import { useGlobalContext } from "../../../useContext/contextApi"
import LoadingIcons from "react-loading-icons"

export default function Desktop() {
	const { isPending, message, twitterAuthBtnText, isTwitterAuthBtnDisabled } = useGlobalContext()
	return (
		<main className='personaWrapperDesktop'>
			<Navbar />
			<section className='sectionOne'>
				<div className='left'>
					<h1>early access</h1>
					<h2>Early access is closed for now. Persona NFT minting will be announced.</h2>
					<div className='step1'>
						<div className='textbox'>
							<div className='index'>01</div>
							<div className='dot'></div>
							<div className='text'>
								<div className='t1'>FOLLOW FIRSTBATCH</div>
								<div id='infoBox1' className='t2'></div>
							</div>
						</div>
						<a href='https://twitter.com/FirstBatchxyz_' className='button' rel='noreferrer' target='_blank'>
							FOLLOW US
						</a>
					</div>
					<div className='step2'>
						<div className='textbox'>
							<div className='index'>02</div>
							<div className='dot'></div>
							<div className='text'>
								<div className='t1'>JOIN WAITLIST</div>
								{/* <div id='infoBox2' className='t2'>
									Early access is closed for now. Persona NFT minting will be announced.
								</div> */}
							</div>
						</div>
						<div className={isTwitterAuthBtnDisabled ? "button twitterAuthBtn disabled" : "button twitterAuthBtn disabled"}>
							{isPending ? (
								<LoadingIcons.Puff />
							) : twitterAuthBtnText === "CONNECTED" ? (
								<span className='btnInner'>
									<IoIosCheckmarkCircle /> <span>{twitterAuthBtnText}</span>
								</span>
							) : (
								<span className='btnInner'>
									<RiTwitterFill /> <span>{twitterAuthBtnText}</span>
								</span>
							)}
							<div className='personaInfoBoxDesktop'>
								<span>{message}</span>
							</div>
						</div>
					</div>
				</div>
				<div className='right'>
					<div className='announcementBox'>
						<div className='label'>
							<h2>TO BE ANNOUNCED</h2>
							<div className='gradientBox'>
								<img src={BgGradient} alt='Background Gradient' />
							</div>
						</div>
						<div className='step3'>
							<div className='textbox'>
								<div className='index'>03</div>
								<div className='dot'></div>
								<div className='text'>
									<div className='t1'>MINT PERSONA NFT (FREE)</div>
									<div className='t2'></div>
								</div>
							</div>
							<div className='button'>MINT</div>
						</div>
						<div className='step4'>
							<div className='textbox'>
								<div className='index'>04</div>
								<div className='dot'></div>
								<div className='text'>
									<div className='t1'>RECEIVE 100 $BATCH</div>
									<div className='t2'></div>
								</div>
							</div>
							<div className='button'>CLAIM</div>
						</div>
					</div>
				</div>
			</section>
			<section className='sectionTwo'>
				<div className='left'>
					<PersonaCardDetailed />
				</div>
				<div className='right'>
					<h3>PERSONA TRAITS</h3>
					{personaTraits.map((trait, index) => {
						return <PersonaTrait key={index} traits={trait} />
					})}
				</div>
			</section>
			<Footer />
		</main>
	)
}
