import React, { useEffect } from "react"
import Desktop from "./views/Desktop.jsx"
import Mobile from "./views/Mobile.jsx"
import { useGlobalContext } from "../../useContext/contextApi"

export default function Persona() {
	const { queryChecker, setMessage } = useGlobalContext()

	useEffect(() => {
		if (window.location.href.includes("?")) {
			const url = new URL(window.location.href)
			let taskId = url.searchParams.get("task_id")
			if (taskId !== "null") {
				queryChecker(taskId)
			}

			if (taskId === "null") {
				setMessage("User Declined Authorization")
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className='personaWrapper'>
			<Desktop />
			<Mobile />
		</div>
	)
}
