import React, { useEffect, useState } from "react"
import Navbar from "../../components/Navbar.jsx"
import NavbarMobile from "../../components/NavbarMobile.jsx"
import Footer from "../../components/Footer.jsx"
import Circle1 from "../../assets/images/firstPlaceCircle.png"
import Circle2 from "../../assets/images/secondPlaceCircle.png"
import Circle3 from "../../assets/images/thirdPlaceCircle.png"
import Badge1 from "../../assets/images/firstPlaceBadge.png"
import Badge2 from "../../assets/images/secondPlaceBadge.png"
import Badge3 from "../../assets/images/thirdPlaceBadge.png"
import { FiStar } from "react-icons/fi"
import LoadingIcons from "react-loading-icons"
import { useGlobalContext } from "../../useContext/contextApi"

const PersonaMap = () => {
	const { isPending, setMessage, web3QueryChecker, myMap } = useGlobalContext()

	useEffect(() => {
		if (window.location.href.includes("?")) {
			const url = new URL(window.location.href)

			let uuid = url.searchParams.get("uuid")

			if (uuid !== "null") {
				web3QueryChecker(uuid)
			}

			if (uuid === "null") {
				setMessage("User Declined Authorization")
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (isPending) {
		return (
			<div className='personaMapWrapper'>
				<Navbar />
				<NavbarMobile />
				<div className='pageContent'>
					<div className='loadingContainer'>
						<LoadingIcons.Puff />
					</div>
				</div>
				<Footer />
			</div>
		)
	}

	return (
		<div className='personaMapWrapper'>
			<Navbar />
			<NavbarMobile />
			<div className='pageContent'>
				{myMap.analysis.length !== 0 && (
					<>
						<div className='stars'>
							<div className={myMap.quality > 0 ? "s1 active" : "s1"}>
								<FiStar />
							</div>
							<div className={myMap.quality > 1 ? "s2 active" : "s2"}>
								<FiStar />
							</div>
							<div className={myMap.quality > 2 ? "s3 active" : "s3"}>
								<FiStar />
							</div>
						</div>
						<div className='cryptoTitle'>{myMap.quality_string}</div>
					</>
				)}
				{myMap.analysis.length !== 0 && (
					<div className='share1'>
						<div className='l1'></div>
						<a rel='noreferrer' target='_blank' href='https://firstbatch.xyz/where-do-you-belong' className='b2'>
							DISCOVER YOURS
						</a>
						<div className='l2'></div>
					</div>
				)}
				<div className='results'>
					{myMap.analysis.length !== 0 && (
						<div className='bottom bottom1'>
							<div className='line'></div>
							<div className='label'>You’re involved in</div>
						</div>
					)}
					{myMap.analysis.length !== 0 &&
						myMap.analysis.map((scope, index) => {
							return (
								<div key={`macro${index}`} className='resultRow'>
									<div className='top'>
										<div className='label'>
											<div className='index'>0{index + 1}</div>
											<div className='line'></div>
											<div className='title'>{scope.scope}</div>
										</div>
										<div className='output'>
											{scope.result.map((result, index) => {
												if (index === 0 && result.interest_rate !== "No Interest") {
													return (
														<div key={`micro${index}`} className='result'>
															<a href={result.twitter_url} rel='noreferrer' target='_blank' className='imgContainer'>
																<img className='circle' src={Circle1} alt='Circle' />
																<img className='badge' src={Badge1} alt='Badge' />
																<img className='formationImg' src={result.image_url} alt='' />
															</a>
															<div className='v1'>{result.formation}</div>
															{/* <div className='v2'>{result.interest_rate}</div> */}
														</div>
													)
												} else if (index === 1 && result.interest_rate !== "No Interest") {
													return (
														<div key={`micro${index}`} className='result'>
															<a href={result.twitter_url} rel='noreferrer' target='_blank' className='imgContainer'>
																<img className='circle' src={Circle2} alt='Circle' />
																<img className='badge' src={Badge2} alt='Badge' />
																<img className='formationImg' src={result.image_url} alt='' />
															</a>
															<div className='v1'>{result.formation}</div>
															{/* <div className='v2'>{result.interest_rate}</div> */}
														</div>
													)
												} else if (index === 2 && result.interest_rate !== "No Interest") {
													return (
														<div key={`micro${index}`} className='result'>
															<a href={result.twitter_url} rel='noreferrer' target='_blank' className='imgContainer'>
																<img className='circle' src={Circle3} alt='Circle' />
																<img className='badge' src={Badge3} alt='Badge' />
																<img className='formationImg' src={result.image_url} alt='' />
															</a>
															<div className='v1'>{result.formation}</div>
															{/* <div className='v2'>{result.interest_rate}</div> */}
														</div>
													)
												} else {
													return (
														<div
															key={`micro${index}`}
															className={result.interest_rate === "No Interest" ? "result faded" : "result"}
														>
															<a
																href={result.twitter_url}
																rel='noreferrer'
																target='_blank'
																className={
																	result.interest_rate === "No Interest"
																		? "imgContainer imgS sm"
																		: "imgContainer imgS"
																}
															>
																<img className='formationImg' src={result.image_url} alt='' />
															</a>
															{result.interest_rate !== "No Interest" && <div className='v1'>{result.formation}</div>}
														</div>
													)
												}
											})}
										</div>
										{scope.isAllEmpty && (
											<div className='isAllEmpty'>
												It seems your social profile isn’t linked with any communities in this area.
											</div>
										)}
									</div>
									{index === 0 && (
										<div className='bottom'>
											<div className='line'></div>
											<div className='label'>You belong to</div>
										</div>
									)}
									{index === 1 && (
										<div className='bottom'>
											<div className='line'></div>
											<div className='label'>You’re aligned with</div>
										</div>
									)}
									{index === 2 && (
										<div className='share'>
											<div className='l1'></div>
											<a rel='noreferrer' target='_blank' href='https://firstbatch.xyz/where-do-you-belong' className='b2'>
												DISCOVER YOURS
											</a>
											<div className='l2'></div>
										</div>
									)}
								</div>
							)
						})}
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default PersonaMap
