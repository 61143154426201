import axios from "axios"

let isRequestSent = false

export default function twitterAuth() {
	if (isRequestSent) return
	isRequestSent = true

	axios
		.get("https://api2.firstbatch.xyz/2/twitter_auth?callback_url=web3-map")
		.then(res => {
			window.location.href = res.data
			isRequestSent = false
		})
		.catch(err => {
			console.log(err.response)
			isRequestSent = false
		})
}
